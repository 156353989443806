import React, { useState } from 'react'


type DataType = { [key: string]: any }

export const ThemeContext = React.createContext<DataType>({})

export type ThemeColors = 'blue' | 'product-moss' | 'green' | 'purple' | 'moss-green' | 'yellow' | 'orange' | 'rouge' | 'mauve' | 'moss' | 'brown' | 'gray' | 'pink' | 'egg-plant' | 'strawberry'

import { themeColors } from "@/styles/color-pallete"

const useValue = () => {


    const [themeColor, setThemeColor] = useState<ThemeColors>('blue')


    function setTheme({ color }: { color: ThemeColors }) {
        const _color = color ?? 'blue'
        const root = document.documentElement;
        Object.entries(themeColors[_color]).forEach(([key, value]) => {

            root.style.setProperty(`--primary-${key}`, value);
        });
        setThemeColor(color || 'blue')
    }

    return {
        themeColor,
        setTheme
    }
}


export const ThemeProvider = ({ children }) => {
    return <ThemeContext.Provider value={useValue()}>


        {children}

    </ThemeContext.Provider>
}
'use client'
import React, { forwardRef, RefObject, Suspense } from 'react'
import { DialogProvider } from '@aw/dialog'
import ErrorBoundary from 'components/Misc/ErrorBoundary'
import Swr from 'components/Misc/Swr'
import { VideoPlayerContextProvider } from 'components/videoPlayerPopup/context'
import { WorkSpaceProvider } from 'context/workSpaceContext'
import Script from "next/script"
import { Toaster } from 'react-hot-toast'
import { Provider as ReduxProvider } from 'react-redux'
import store from 'redux/store'

import { ThemeProvider } from "@/context/ThemeContext"
import { AuthProvider } from "@/modules/auth/context"
import { Karla, Montserrat, Nunito, Open_Sans } from 'next/font/google'

import { ConfigProvider } from "antd"
import './globals.css'
import "antd/dist/reset.css";


export const nunito = Nunito({
  subsets: ['latin'],
  variable: '--font-nunito',
  display: 'swap'
})

export const montserrat = Montserrat({
  subsets: ['latin'],
  variable: '--font-montserrat',
  display: 'swap'
})

export const karla = Karla({
  subsets: ['latin'],
  variable: '--font-karla',
  display: 'swap',
  preload: true
})

export const OpenSans = Open_Sans({
  subsets: ['latin'],
  variable: '--font-open-sans',
  display: 'swap'
})

export type AppLayoutProps = {
  breadcrumb?: () => JSX.Element
  children?: React.ReactNode
}

// eslint-disable-next-line react/display-name
const AppLayout = forwardRef<RefObject<HTMLElement>, AppLayoutProps>(({ children }, ref) => {

  // TODO - use screen hook to detect small device & add body class - overflow-hidden
  const refProp = ref ? { ref: ref } : {}
  return (
    <html className={`${karla.variable} ${montserrat.variable} ${OpenSans.variable}`}>
      <Script src="https://accounts.google.com/gsi/client"></Script>
      {
        process.env.NEXT_PUBLIC_ENVIRONMENT_MODE && process.env.NEXT_PUBLIC_ENVIRONMENT_MODE === 'production' && <Script id="clarity-analytics" type="text/javascript">
          {`
         (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "q22qbtzh13");
        `}
        </Script>
      }

      <body className="antialiased" >
        <Suspense>
          <ErrorBoundary>
            <ThemeProvider>
              <ReduxProvider store={store}>
                <VideoPlayerContextProvider>
                  <WorkSpaceProvider>
                    <DialogProvider>
                      <AuthProvider>
                        <Swr>
                          <Toaster position="bottom-center" />
                          {children}
                          {/* <Main {...props} ref={ref} /> */}
                        </Swr>
                      </AuthProvider>
                    </DialogProvider>

                  </WorkSpaceProvider>
                </VideoPlayerContextProvider>
              </ReduxProvider>
            </ThemeProvider>
          </ErrorBoundary >
        </Suspense>
      </body >
    </html >
  )
})

export default AppLayout
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the types
export type Template = {
    id: string;
    title: string;
    content: string;
    type: string; // Could be "header" or "footer"
};

export type ApplicationStatus = {
    id: string | null;
    count: number | null;
};

export type Job = {
    id: number | null;
    title: string;
    slug: string;
    location: string;
    salary_from: number; // Changed to number for consistency
    salary_to: number; // Changed to number for consistency
    status: string; // e.g., "open"
    role: string;
    description_headers: string[];
    description: string;
    description_footers: string[];
    templates: Template[];
    excerpt: string;
    pinned: number; // 0 or 1
    source_currency_id: number;
    target_currency_id: number;
    required_experiance: string;
    compensation: string; // e.g., "Hourly"
    application_deadline: string; // ISO date string
    qualification: string;
    type: string; // e.g., "Primary"
    recommended: ApplicationStatus; // Replace with specific type if known
    applied: ApplicationStatus;
    incomplete: ApplicationStatus;
    pending_evaluation: ApplicationStatus;
    invite_sent: ApplicationStatus;
    slot_selected: ApplicationStatus;
    scheduled: ApplicationStatus;
    interviewed: ApplicationStatus;
    rejected: ApplicationStatus;
    hired: ApplicationStatus;
    total: number | null;
    tags: string[]; // Empty array, but could hold tag strings
    job_type_id: number | null; // job setup
    experience_level_id: number; // Added as inferred from initial state,
    country_id: number | null;
    state_id: number | null;
    city_id: number | null;
    job_fields: { id: number, name: string }[]; // Added as inferred from initial state
    available_job_field_ids: number[]; // Added as inferred from initial state
    hire_tenure: string | null
    job_location: string | null //to render location
    job_type: { name: string, id: number } | null //to render job type
};

// Define the initial state type
type LoaderState = {
    loading: boolean;
    text: string;
};

type JobState = Omit<Job, 'id'> & {
    id: number | null;
    interviewer_ids: number[]; // Added as inferred from initial state
    question_id: number[]; // Added as inferred from initial state
};

type State = {
    job: JobState;
    loader: LoaderState;
};

// Initial state
const initialJobState: JobState = {
    id: null,
    title: '',
    location: '',
    role: '',
    description: '',
    excerpt: '',
    pinned: 0,
    job_type_id: 1,
    source_currency_id: 1,
    target_currency_id: 1,
    salary_from: 0,
    salary_to: 0,
    compensation: 'Hourly',
    required_experiance: '',
    type: 'Primary',
    qualification: '',
    application_deadline: '',
    tags: [],
    status: 'open',
    slug: '',
    interviewer_ids: [],
    question_id: [],
    description_headers: [],
    description_footers: [],
    templates: [],
    applied: { id: null, count: null },
    incomplete: { id: null, count: null },
    pending_evaluation: { id: null, count: null },
    invite_sent: { id: null, count: null },
    slot_selected: { id: null, count: null },
    scheduled: { id: null, count: null },
    interviewed: { id: null, count: null },
    rejected: { id: null, count: null },
    hired: { id: null, count: null },
    total: null,
    recommended: { id: null, count: null },
    experience_level_id: 1,
    city_id: null,
    state_id: null,
    country_id: null,
    job_fields: [],
    hire_tenure: null,
    job_location: null,
    job_type: null
};

const initialState: State = {
    job: initialJobState,
    loader: {
        loading: false,
        text: '',
    },
};

// Create slice
export const job = createSlice({
    name: 'job',
    initialState,
    reducers: {
        setLoader: (state, action: PayloadAction<{ loading: boolean; text: string }>) => {
            state.loader.loading = action.payload.loading;
            state.loader.text = action.payload.text;
        },
        setProperty: <K extends keyof JobState>(state, action: PayloadAction<{ property: K; value: JobState[K] }>) => {
            state.job[action.payload.property] = action.payload.value;
        },
        resetJob: () => initialState,
        setJob: (state, action: PayloadAction<Partial<JobState>>) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                if (key in state.job) {
                    state.job[key as keyof JobState] = value as any;
                }
            });
        },
    },
});

export const { setProperty, resetJob, setLoader, setJob } = job.actions;

export default job.reducer;


export const themeColors = {
    blue: {
        50: 'rgb(241, 245, 255)',//#F1F5FF,
        100: 'rgb(217, 223, 242)',//#D9DFF2,
        200: 'rgb(174, 186, 227)', //#AEBAE3 ,
        300: 'rgb(136, 154, 213)', //#889AD5 ,
        400: 'rgb(100, 123, 201)', //#647BC9,
        500: 'rgb(66, 94, 189)', //#425EBD,
        600: 'rgb(62 88, 179)', //#3850A1,
        700: 'rgb(47, 67, 134)', //#2F4386,
        800: 'rgb(38, 54, 108)',// #26366C,
        900: 'rgb(30, 42, 85)', //#1E2A55  ,      
    }
    ,
    'product-moss': {
        50: 'rgb(240, 247, 247)',
        100: 'rgb(220, 235, 237)',
        200: 'rgb(192, 218, ​222)',
        300: 'rgb(163, 201, 206)',
        400: 'rgb(135, 185, 192)',
        500: 'rgb(109, 170, 178)',
        600: 'rgb(93, 145, 151)',
        700: 'rgb(77, 121, 126)',
        800: 'rgb(62, ​97, ​10)',
        900: 'rgb(49, 76, 80)',
    }
    ,
    'green': {

        50: 'rgb(239, 248, 237)',
        100: 'rgb(217, 237, 213)',
        200: 'rgb(186, 223, 179)',
        300: 'rgb(154, 208, 143)',
        400: 'rgb(124, 194, 110)',
        500: 'rgb(95, 181, 78)',
        600: 'rgb(81, 154, 66)',
        700: 'rgb(67, 129, 55)',
        800: 'rgb(54, 103, 44)',
        900: 'rgb(43, 81, 35)',

    }
    ,

    info: {
        50: '#e6f7fc',//rgb(230, 247, 252)
        100: '#b1e7f6',//rgb(177, 231, 246)
        200: "#8cdcf1",//rgb(140, 220, 241)
        300: "#57cbeb",//rgb(87, 203, 235)
        400: "#36c1e7",//rgb(54, 193, 231)
        500: "#04b2e1",//rgb(4, 178, 225)
        600: "#04a2cd",//rgb(4, 162, 205)
        700: "#037ea0",//rgb(3, 126, 160)
        800: "#02627c",//rgb(2, 98, 124)
        900: "#024b5f",//rgb(2, 75, 95)

    },
    'purple': {
        50: 'rgb(246, 240, 250',
        100: 'rgb(232, 219, 243)',
        200: 'rgb(214, 191, 233',
        300: 'rgb(195, 161, 222)',
        400: 'rgb(177, 133, 212)',
        500: 'rgb(160, 106, 203)',
        600: 'rgb(136, 90, 173)',
        700: 'rgb(114, 75, 144)',
        800: 'rgb(91, 60, 116)',
        900: 'rgb(72, 48, 91)',

    }
    ,
    'moss-green': {
        50: 'rgb(242, 245, 242)',
        100: 'rgb(223, 231, 225)',
        200: 'rgb(198, 212, 201)',
        300: 'rgb(171, 192, 176)',
        400: 'rgb(146, 173, 152)',
        500: 'rgb(122, 155, 129)',
        600: 'rgb(104, 132, 110)',
        700: 'rgb(87, 110, 92)',
        800: 'rgb(70, 88, 74)',
        900: 'rgb(55, 70, 58)',

    }
    ,
    'yellow': {
        50: 'rgb(255, 250, 235)',
        100: 'rgb(255, 244, 206)',
        200: 'rgb(254, 235, 168)',
        300: 'rgb(254, 226, 127)',
        400: 'rgb(253, 217, 89)',
        500: 'rgb(253, 209, 52)',
        600: 'rgb(215, 178, 44)',
        700: 'rgb(180, 148, 37)',
        800: 'rgb(144, 119, 30)',
        900: 'rgb(114, 94, 23)',
    }
    ,
    orange: {
        50: 'rgb(253, 244, 236)',
        100: ' rgb(250, 229, 209)',
        200: ' rgb(246, 208, 173)',
        300: ' rgb(242, 186, 135)',
        400: ' rgb(239, 166, 99)',
        500: ' rgb(235, 146, 65)',
        600: ' rgb(200, 124, 55)',
        700: ' rgb(167, 104, 46)',
        800: ' rgb(134, 83, 37)',
        900: ' rgb(106, 66, 29)',
    }
    ,
    rouge: {
        50: 'rgb(252, 241, 238)',
        100: 'rgb(247, 221, 215)',
        200: 'rgb(241, 194, 184)',
        300: 'rgb(235, 166, 150)',
        400: 'rgb(229, 139, 119)',
        500: 'rgb(223, 113, 89)',
        600: 'rgb(190, 96, 76)',
        700: 'rgb(158, 80, 63)',
        800: 'rgb(127, 64, 51)',
        900: 'rgb(100, 51, 40)',
    }
    ,
    mauve: {
        50: 'rgb(246, 236, 239)',
        100: 'rgb(233, 209, 217)',
        200: 'rgb(215, 173, 186)',
        300: 'rgb(197, 135, 154)',
        400: 'rgb(180, 99, 124)',
        500: 'rgb(193, 114, 138)',
        600: 'rgb(164, 97, 117)',
        700: 'rgb(137, 81, 98)',
        800: 'rgb(110, 65, 79)',
        900: 'rgb(73, 29, 43)',

    }
    ,
    moss: {
        50: 'rgb(240, 247, 247)',
        100: 'rgb(220, 235, 237)',
        200: 'rgb(192, 218, 222)',
        300: 'rgb(163, 201, 206)',
        400: 'rgb(135, 185, 192)',
        500: 'rgb(109, 170, 178)',
        600: 'rgb(93, 145, 151)',
        700: 'rgb(77, 121, 126)',
        800: 'rgb(62, 97, 101)',
        900: 'rgb(49, 76, 80)',
        nav: 'linear-gradient(180deg, #5D9197 0%, #3E6165 100%)'
    }
    ,
    brown: {
        50: 'rgb(241, 234, 230)',
        100: 'rgb(222, 205, 194)',
        200: 'rgb(196, 166, 145)',
        300: 'rgb(168, 124, 94)',
        400: 'rgb(142, 84, 46)',
        500: 'rgb(117, 47, 0)',
        600: 'rgb(99, 40, 0)',
        700: 'rgb(83, 33, 0)',
        800: 'rgb(67, 27, 0)',
        900: 'rgb(53, 21, 0)',

    }
    ,
    gray: {
        50: 'rgb(238, 238, 238)',
        100: 'rgb(215, 215, 215)',
        200: 'rgb(183, 183, 183)',
        300: 'rgb(149, 149, 149)',
        400: 'rgb(117, 117, 117)',
        500: 'rgb(87, 87, 87)',
        600: 'rgb(74, 74, 74)',
        700: 'rgb(62, 62, 62)',
        800: 'rgb(50, 50, 50)',
        900: 'rgb(39, 39, 39)',
    }

    ,
    pink: {
        50: '#FFF3FF',
        100: '#FEE2FF',
        200: '#FECBFF',
        300: '#FDB2FE',
        400: '#FD9BFE',
        500: '#FC85FE',
        600: '#D671D8',
        700: '#B35EB4',
        800: '#904C91',
        900: '#713C72',
    },
    'egg-plant': {
        50: '#F0EFF5' /*rgb(240, 239, 245)*/,
        100: '#D3CFE2' /*rgb(211, 207, 226)*/,
        200: '#B5AFCF' /*rgb(181, 175, 207)*/,
        300: '#9790BC' /*rgb(151, 144, 188)*/,
        400: '#7A70A9' /*rgb(122, 112, 169)*/,
        500: '#60568F' /*rgb(96, 86, 143)*/,
        600: '#4B436F' /*rgb(75, 67, 111)*/,
        700: '#3C355E' /*rgb(60, 53,94)*/,
        800: '#2F2A46' /*rgb(47, 42, 70)*/,
        900: '#221F33' /*rgb(34, 31, 51)*/,
    },
    strawberry: {
        50: '#FFF3F8' /*rgb(255, 243, 248)*/,
        100: '#FFDBE9' /*rgb(255, 219, 233)*/,
        200: '#FFC4DA' /*rgb(255, 196, 218)*/,
        300: '#FFB7D3' /*rgb(255, 183, 211)*/,
        400: '#FF9FC4' /*rgb(255, 159, 196)*/,
        500: '#FF85B6' /*rgb(255, 133, 182)*/,
        600: '#DE739E' /*rgb(222, 115, 158)*/,
        700: '#BE6186' /*rgb(190, 94, 137)*/,
        800: '#804059' /*rgb(128, 64, 89)*/,
        900: '#482130' /*rgb(72, 33, 48)*/,
    }
}